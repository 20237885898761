/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import {
  DetailsList,
  Selection,
  SelectionMode,
  ColumnActionsMode,
  DetailsListLayoutMode,
  ConstrainMode,
} from "@fluentui/react/lib/DetailsList";
import { Icon } from "@fluentui/react/lib/Icon";
import { mergeStyleSets } from "@fluentui/react/lib/Styling";
import { ContextualMenu } from "@fluentui/react/lib/ContextualMenu";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { HiDownload, HiRefresh, HiOutlineTrash } from "react-icons/hi";
import {
  MdFindReplace,
  MdOutlineEdit,
  MdChatBubble,
  MdSend,
  MdCalendarToday,
  MdThumbDown,
  MdOutlineCloudUpload,
} from "react-icons/md";
import { GrView, GrClose } from "react-icons/gr";
import { MdClose } from "react-icons/md";
import "./dataGrid.css";
import { checkIfDeviceIsMob } from "../../utility/utility";
import Constant from "../../utility/constants";
import { getPrivilegeValue } from "../../utility/utility";
import { SearchAndFilter } from "../searchAndFilterColumn/searchAndFilterColumn";
import { TooltipHost, tooltipHostProps } from "@fluentui/react";
import DialogBox from "../dialogBox/DialogBox";
import FooterTechButtons from "../commonformcomponents/footertechbuttons/FooterTechButtons";
import TextArea from "../commonformcomponents/textarea/TextArea";
import Label from "../commonformcomponents/label/Label";
import { UseToasterNotificationsContext } from "../../context/toasternotificationscontext/toasterNotificationsContext";

initializeIcons();

function DataGrid({
  _columnsFromProps,
  _data,
  _contextualMenuOptionsFromProps,
  handleDownload,
  handlePublish,
  handleDelete,
  handleUploadExcelPPOD,
  handleEdit,
  handleRefresh,
  handleDisLike,
  loadMoreData,
  hasMoreData,
  pageNo,
  handleSelection,
  disableDownload,
  disableDelete,
  disablePPODUpload,
  disableRefresh,
  disableEdit,
  disableActionItems,
  disablePublish,
  renderColumns,
  displayDownload,
  displayDelete,
  displayPPODUpload,
  displayRefresh,
  displayPublish,
  displayEdit,
  displayView,
  displayActionItems,
  privilagesInternalApp,
  configLimit,
  fileID,
  appDetails,
  tpAlerts,
  appSearchQueryValue,
  columnFilteringUpdateMethodDataGrid,
  searchFilters,
  pageRoute,
  templateDetails,
  removeSelection,
  resetRemoveSelection,
  downloadConfigLimit,
}) {
  const conditionalDataGridContainer =
    fileID !== "Database" ? "datagrid_container" : "powerK_datagrid_container";
  const conditionalDataMenuContainer =
    fileID !== "Database"
      ? "datagrid_menucontainer"
      : "powerK_datagrid_menucontainer";
  const columnToBeEdited = useRef(undefined);
  const scrollDiv = useRef();
  const [verticalScrollPosition, setverticalScrollPosition] = useState(0);
  const [downloadVisible, setdownloadVisible] = useState(disableDownload);
  const [deleteVisible, setDeleteVisible] = useState(disableDelete);
  const [editVisible, seteditVisible] = useState(disableEdit);
  const [uploadExcelPPODDisabled, setUploadExcelPPODDisabled] =
    useState(disablePPODUpload);
  const [actionItemsVisible, setActionItemsVisible] =
    useState(disableActionItems);
  const [publishVisible, setPublishVisible] = useState(disablePublish);
  const [isDislike, setIsDislike] = useState(false);
  const [isChat, setIsChat] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [isCalendar, setIsCalendar] = useState(false);
  const [gridData, setGridData] = useState([..._data]);
  const gridDataRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isConfirmButtonDisabled, setIsConfirmButtonDisabled] = useState(true);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [disLikeFeeback, setDisLikeFeeback] = useState("");
  const [errorState, setErrorState] = useState({ DisLikeFeedback: {} });
  const [isSelectAllCheckboxVisible, setSelectAllCheckboxVisible] =
    useState(false);
  const isMaxSelectedCount = useRef(false);
  const[triggerSelectAllManually, setTriggerSelectAllManually] = useState(false);
  const firstSelectedConfigItem = useRef(null);
  const contentTypeMappingForInternalApps = {
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.POWERK]: true,
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.TPALERTS]: true,
    [Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES]: true,
    // Add more content types here as needed
  };
  const onHideContextualMenu = () => {
    setContextualMenuProps(undefined);
  };
  const EDIT_CONFIG_LIMIT = Constant.INTERNAL_APPS_GENERAL_CONSTANTS.EDIT_LIMIT;
  const DOWNLOAD_EXPORT_CONFIG_LIMIT =
    pageRoute === "Database"
      ? Constant.INTERNAL_APPS_GENERAL_CONSTANTS.DOWNLOAD_LIMIT
      : downloadConfigLimit;
  const EditErrorToolTip = `Maximum ${EDIT_CONFIG_LIMIT} files allowed for edit.`;
  const Download_Export_ErrorToolTip = `Maximum ${DOWNLOAD_EXPORT_CONFIG_LIMIT} files allowed for ${
    pageRoute === "Database" ? "download" : "export"
  }.`;
  const CANCEL_TEXT = Constant.INTERNALAPP_CONSTANTS.BTN_CANCEL;
  const BTN_SUBMIT_FEEDBACK =
    Constant.INTERNALAPP_CONSTANTS.BTN_SUBMIT_FEEDBACK;
  const EXPORT_DOWNLOAD_HOVER_TOOLTIP =
    pageRoute === "Database"
      ? Constant.INTERNAL_APPS_ICONS_TOOLTIP.DOWNLOAD
      : Constant.INTERNAL_APPS_ICONS_TOOLTIP.EXPORT;

  const { handleNotificationMsgsData } = UseToasterNotificationsContext();

  useEffect(() => {
    setSelectAllCheckboxVisible(
      pageRoute === "Library" &&
        contentTypeMappingForInternalApps[appDetails?.contentTypeId]
    );
    setIsDislike(
      pageRoute === "Database"
        ? false
        : getPrivilegeValue(privilagesInternalApp, "like", "display")
    );
    // setIsChat(getPrivilegeValue(privilagesInternalApp, 'chat', 'display'));
    // setIsShare(getPrivilegeValue(privilagesInternalApp, 'share', 'display'));
    // setIsCalendar(getPrivilegeValue(privilagesInternalApp, 'calendar', 'display'));
    setIsChat(false);
    setIsShare(false);
    setIsCalendar(false);
  }, [privilagesInternalApp]);

  useEffect(() => {
    setIsConfirmButtonDisabled(
      !(disLikeFeeback?.length > 0 && disLikeFeeback.length <= 2000)
    );
  }, [disLikeFeeback]);

  useEffect(() => {
    if(contentTypeMappingForInternalApps[appDetails?.contentTypeId] && triggerSelectAllManually){
      
      _selection.current.setAllSelected(true);
      _getSelectionDetails();
    }
  },[triggerSelectAllManually]);

  const getInitialContextualMenuProps = (columnKey) => {
    const itemOnClick = (event, clickedItem, sortOrder) => {
      const newItems = cloneDeep(
        columnToBeEdited.current.contextualMenuProps
      ).items.map((item) => {
        if (item.key === clickedItem.key) {
          item.checked = true;
        } else {
          item.checked = false;
        }
        return item;
      });
      const output = {
        items: newItems,
        target: undefined,
        gapSpace: 10,
        isBeakVisible: true,
        onDismiss: onHideContextualMenu,
      };
      const newColumns = _columns.map((column) => {
        if (column.key === columnToBeEdited.current.key) {
          column.contextualMenuProps = { ...output };
        } else {
          column?.contextualMenuProps?.items.map((item) => {
            item.checked = false;
            return item;
          });
        }
        return column;
      });
      setColumns(newColumns);
      sortItems(columnToBeEdited.current, sortOrder);
    };

    const onRenderIcon = ({ item }) => {
      return (
        <>
          {item.checked ? (
            <span className={classNames.iconContainer}>
              <Icon iconName="CheckMark" className={classNames.logoFillIcon} />
              <Icon iconName="CheckMark" className={classNames.logoIcon} />
            </span>
          ) : (
            <span
              className={classNames.iconContainer}
              style={{ visibility: "hidden" }}
            >
              <Icon iconName="CheckMark" className={classNames.logoFillIcon} />
              <Icon iconName="CheckMark" className={classNames.logoIcon} />
            </span>
          )}
        </>
      );
    };

    const contextMenuItems = _contextualMenuOptionsFromProps[columnKey];
    const items = contextMenuItems.map((item, i) => {
      return {
        key: item.label,
        text: item.label,
        hasIcons: item.hasIcons,
        onClick: (event, clickedItem) =>
          itemOnClick(event, clickedItem, item.sortOrder),
        checked: false,
        iconProps: {},
        onRenderIcon: onRenderIcon,
      };
    });
    return {
      items: items,
      target: undefined,
      gapSpace: 10,
      isBeakVisible: true,
      onDismiss: onHideContextualMenu,
      hidden: true,
    };
  };

  const getContextualMenuPropsForColumns = (columnKey) => {
    return getInitialContextualMenuProps(columnKey);
  };

  const initializeFilterAppliedCountProperties = () => {
    if (searchFilters && Object.keys(searchFilters).length > 0) {
      const filterCounts = Object.keys(searchFilters).map((key) => {
        return { fieldName: key, count: searchFilters[key].length };
      });
      return filterCounts;
    }
    return [];
  };
  const initializeColumns = () => {
    const newColumns = _columnsFromProps.map((column) => {
      column.onColumnClick = (...args) => {
        if (contentTypeMappingForInternalApps[appDetails?.contentTypeId]) {
        } else {
          _onColumnClick(...args);
          column.onClick(...args);
        }
      };
      // below line is to enbale multiline for the column
      if (column.isMultilineEnable) {
        column.isMultiline = true;
      }
      if (contentTypeMappingForInternalApps[appDetails?.contentTypeId]) {
        if (column.isFiltered) {
          column.onRenderHeader = () => {
            return (
              <span className="my-custom-filter-icon">
                <span className="columnName-filter-icon">{column.name} </span>
                <div className="searchAndFilterComponent-circleNumber">
                  <SearchAndFilter
                    appDetails={appDetails}
                    appSearchQueryValue={appSearchQueryValue}
                    fieldName={column.fieldName}
                    columnName={column.name}
                    fieldType={column.type}
                    columnFilteringUpdateMethod={(
                      fieldName,
                      fieldNameFilters,
                      applyOrClearFilter
                    ) => {
                      columnFilteringMethodSearchAndFilter(
                        fieldName,
                        fieldNameFilters,
                        applyOrClearFilter,
                        column.type
                      );
                    }}
                    searchFilters={searchFilters}
                    templateDetails={templateDetails}
                    pageRoute={pageRoute}
                  />
                  {filteredAppliedCountArray.current.filter(
                    (item) => item?.fieldName === column.fieldName
                  ).length > 0 &&
                    filteredAppliedCountArray.current.filter(
                      (item) => item?.fieldName === column.fieldName
                    )[0].count > 0 && (
                      <button className="circleNumber">
                        {filteredAppliedCountArray.current
                          .filter(
                            (item) => item?.fieldName === column.fieldName
                          )[0]
                          .count.toString()}
                      </button>
                    )}
                </div>
              </span>
            );
          };
        }
        column.isFiltered = false;
      }
      // below line is to check column has dropdown or not
      column.columnActionsMode =
        column.hasDropdown && ColumnActionsMode.hasDropdown;
      column.contextualMenuProps =
        _contextualMenuOptionsFromProps[column.key] &&
        getContextualMenuPropsForColumns(column.key);
      return column;
    });
    return newColumns;
  };
  const [contextualMenuProps, setContextualMenuProps] = useState(undefined);
  const [selectedItems, setselectedItems] = useState([]);
  const [selectedCount, setSelectedCount] = useState(0);
  const [_columns, setColumns] = useState(initializeColumns);
  const filteredAppliedCountArray = useRef(
    initializeFilterAppliedCountProperties()
  );
  const _onColumnClick = (...args) => {
    const [event, column] = args;
    if (column.columnActionsMode !== ColumnActionsMode.disabled) {
      const key = column.key;
      const columnToBePassed = _columns.find((c) => c.key === key);
      columnToBeEdited.current = columnToBePassed;
      setContextualMenuProps(getContextualMenuProps(event, columnToBePassed));
    }
  };

  useEffect(() => {
    setGridData([..._data]);
    gridDataRef.current = _data;
  }, [_data]);

  useEffect(() => {
    setdownloadVisible(disableDownload);
    setDeleteVisible(disableDelete);
    seteditVisible(disableEdit);
    setActionItemsVisible(disableActionItems);
    setPublishVisible(disablePublish);
    setUploadExcelPPODDisabled(disablePPODUpload);
  }, [
    disableDownload,
    disableDelete,
    disableEdit,
    disableActionItems,
    disablePublish,
    disablePPODUpload,
  ]);

  useEffect(() => {
    if (removeSelection) {
      //clearSelection();
      _selection.current.setAllSelected(false);
      if (resetRemoveSelection) {
        resetRemoveSelection();
      }
    }
  }, [removeSelection]);

  useEffect(() => {
    let sd = scrollDiv.current;
    const scrollListern = (e) => {
      const rowElements = document.querySelectorAll("[class='ms-List-cell']");
      const lastRow = rowElements[rowElements.length - 1];
      const elscrollDiv = scrollDiv.current;
      const yOfLastRow = lastRow?.getBoundingClientRect().y;
      const { y: yOfDataGrid, height } = elscrollDiv.getBoundingClientRect();
      const sum = yOfDataGrid + height;
      let currentScrollPosition = scrollDiv.current.scrollTop;
      if (yOfLastRow) {
        if (
          currentScrollPosition !== verticalScrollPosition &&
          yOfLastRow < sum &&
          hasMoreData
        ) {
          loadMoreData(pageNo);
        }
        setverticalScrollPosition(currentScrollPosition);
      }
    };
    const debouncedScrollListener = debounce(scrollListern, 500);
    sd.onscroll = debouncedScrollListener;
  }, [pageNo, hasMoreData]);

  const sortItems = (column, sortOrder) => {
    let col = column.key;
    let items = [...gridDataRef.current];
    if (sortOrder === "ASC") {
      items.sort((a, b) => (a[col] < b[col] ? -1 : 1));
    } else if (sortOrder === "DESC") {
      items.sort((a, b) => {
        return a[col] > b[col] ? -1 : 1;
      });
    }
    _selection.current.setItems([], false);
    setGridData(items);
  };

  const _selection = useRef(
    new Selection({
      onSelectionChanged: (...args) => {
        _getSelectionDetails();
      },
      canSelectItem: (obj, index) => {
        let curSelected = _selection.current.getSelection();
        if (curSelected.length == configLimit) {
          if (
            curSelected.length == configLimit &&
            curSelected.filter((item) => item[fileID] === obj[fileID]).length >
              0
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      },
      selectionMode: SelectionMode.multiple,
    })
  );

  const _getSelectionDetails = () => {
    const selectionCount = _selection.current.getSelectedCount();
    if(contentTypeMappingForInternalApps[appDetails?.contentTypeId]){
    if(selectionCount == 0 && isMaxSelectedCount.current){
      _selection.current.setIndexSelected(firstSelectedConfigItem.current,true, false);
      setTriggerSelectAllManually(true);
    }
    else{
      setTriggerSelectAllManually(false);
    }
    if(configLimit>0 && selectionCount == configLimit){
      isMaxSelectedCount.current = true;
      let firstSelectedIndex = _selection.current.getSelection()[0]
      let index = gridData?.findIndex(
        (item) => item?.metaDataId === firstSelectedIndex?.metaDataId
      );
      if(index !== -1){
      firstSelectedConfigItem.current =  index;
      }   
    }
    else{
      isMaxSelectedCount.current = false;
    }
  }
    setSelectedCount(selectionCount);
    switch (selectionCount) {
      case 0:
        return "No items selected";
      default:
        let items = _selection.current.getSelection();
        setselectedItems(items);
        handleSelection(items, selectionCount);

        if (items.length >= 1) {
          let reloadItem = _selection.current.getItems();
          setGridData([...reloadItem]);
        }
    }
  };

  const classNames = mergeStyleSets({
    iconContainer: {
      position: "relative",
      margin: "0 4px",
      height: 32,
      width: 14,
    },
    logoIcon: {
      position: "absolute",
      left: 0,
      right: 0,
      color: "#000000",
    },
    logoFillIcon: {
      position: "absolute",
      left: 0,
      right: 0,
      color: "#ffffff",
    },
  });

  const getContextualMenuProps = (ev, column) => {
    return {
      ...column.contextualMenuProps,
      target: ev.currentTarget,
      hidden: false,
    };
  };
  function _renderItemColumn(item, index, column) {
    const fieldContent = item[column.fieldName];
    if (renderColumns) {
      return renderColumns(fieldContent, column, item);
    } else {
      return <span>{fieldContent}</span>;
    }
  }

  const handleDownloadClick = () => {
    handleDownload(selectedItems);
  };

  const handlePublishClick = () => {
    handlePublish(selectedItems);
  };

  const handleEditClick = () => {
    handleEdit(selectedItems);
  };

  const handleDeleteClick = () => {
    handleDelete(selectedItems);
  };

  const handleRefreshClick = () => {
    handleRefresh();
  };

  const handleDisLikeFeeback = async () => {
    setIsOpen(false);
    await handleDisLike(selectedItems, disLikeFeeback);
    setDisLikeFeeback("");
  };

  const showNotification = (msg, type) => {
    handleNotificationMsgsData({
      showMsgBar: true,
      started: false,
      completed: true,
      msg,
      type,
      isFailed: false,
    });
  };

  const handleDislikeAction = () => {
    const metaDataAndLikes = selectedItems
      ?.filter(item => item.customData?.userLevel?.liked === false);
    const metaDataAndLikesArrayLength = metaDataAndLikes.length || 0;
    if (metaDataAndLikesArrayLength === 0) {
      const message =
      selectedItems.length > 1
          ? Constant.INTERNALAPP_CONSTANTS.DISLIKE_WARNING_MSG
          : Constant.INTERNALAPP_CONSTANTS.DISLIKE_SINGLE_WARNING_MSG;
      showNotification(message, "Fail");
      return;
    }
    else{
      setIsOpen(true);
    }
  };

  const clearSelection = () => {
    _selection.current.setAllSelected(false);
    let reloadItem = _selection.current.getItems();
    setGridData([...reloadItem]);
  };
  const columnFilteringMethodSearchAndFilter = (
    fieldName,
    fieldNameFilters,
    applyOrClearFilter,
    fieldType = "defaultType"
  ) => {
    columnFilteringUpdateMethodDataGrid(
      fieldName,
      fieldNameFilters,
      applyOrClearFilter,
      fieldType
    );
    if (
      filteredAppliedCountArray.current.filter(
        (item) => item?.fieldName === fieldName
      ).length > 0
    ) {
      const updateFilterCount = filteredAppliedCountArray.current.map(
        (record) => {
          if (record?.fieldName === fieldName) {
            return { ...record, count: fieldNameFilters.length };
          }
          return record;
        }
      );

      filteredAppliedCountArray.current = updateFilterCount;
    } else {
      const newFilterCount = {
        fieldName: fieldName,
        count: fieldNameFilters.length,
      };
      filteredAppliedCountArray.current.push(newFilterCount);
    }
  };

  const updateValidationState = (name, value, hasAnyError, errMessage) => {
    let updatedObj = {};
    let obj = {
      currentValue: value,
      hasAnyError: hasAnyError,
      errorMessage: errMessage,
    };
    updatedObj = {
      ...errorState,
      [name]: obj,
    };
    setErrorState({ ...updatedObj });
  };

  const cancelSubmit = () => {
    setIsOpen(false);
    setDisLikeFeeback("");
    setErrorState({ DisLikeFeedback: {} });
  };
  const onKeyDown = (e) => {
    const isCtrlA = ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'a');
    if (isCtrlA) {
      e.preventDefault();
      e.stopPropagation();
  
      showNotification(Constant.INTERNALAPP_CONSTANTS.CTRLA_NOT_ALLOWED_MSG, "Fail");
      _selection.current.setAllSelected(false);
    }
  };
  const goBackToGrid = () => {
    setIsOpen(false);
    setIsConfirmDialogOpen(false);
    setDisLikeFeeback("");
    setErrorState({ DisLikeFeedback: {} });
  };

  const handleCancelClick = () => {
    setIsConfirmDialogOpen(true);
  };

  const onDislikeFeebackChange = (e) => {
    const newDisLikeFeedback = e.target.value;
    setDisLikeFeeback(newDisLikeFeedback);
    const hasFeedbackError = !newDisLikeFeedback; // Set to true if newDisLikeFeedback is empty
    updateValidationState(
      "DisLikeFeedback",
      newDisLikeFeedback,
      hasFeedbackError,
      hasFeedbackError ? "This information is required" : ""
    );
  };

  return (
    <>
      <div className={conditionalDataGridContainer}>
        <div className={conditionalDataMenuContainer}>
          <div
            className={`actionBtns
          ${checkIfDeviceIsMob() && "mobileClass"}
          `}
          >
            {(displayEdit || displayView) && (
              <span
                tabIndex={0}
                title={
                  selectedCount > EDIT_CONFIG_LIMIT && displayEdit
                    ? EditErrorToolTip
                    : displayEdit
                    ? Constant.INTERNAL_APPS_ICONS_TOOLTIP.EDIT
                    : Constant.INTERNAL_APPS_ICONS_TOOLTIP.VIEW
                }
              >
                <span
                  className={`datagrid_edit
                    ${
                      (selectedCount === 0 || editVisible) &&
                      "datagrid_disabled"
                    }`}
                  onClick={() => {
                    handleEditClick();
                  }}
                  style={
                    ((displayEdit || displayView) && !checkIfDeviceIsMob()) ||
                    contentTypeMappingForInternalApps[appDetails?.contentTypeId]
                      ? {}
                      : { display: "none" }
                  }
                >
                  {displayEdit ? (
                    <MdOutlineEdit className="datagrid_icon_mb" />
                  ) : displayView ? (
                    <GrView className="datagrid_icon_mb" />
                  ) : null}
                </span>
              </span>
            )}
            {displayDownload && (
              <span
                tabIndex={0}
                title={
                  appDetails !== undefined
                    ? selectedCount > DOWNLOAD_EXPORT_CONFIG_LIMIT &&
                      displayDownload
                      ? Download_Export_ErrorToolTip
                      : appDetails?.contentTypeId !==
                        Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES
                      ? EXPORT_DOWNLOAD_HOVER_TOOLTIP
                      : Constant.INTERNAL_APPS_ICONS_TOOLTIP.EXPORTALL
                    : "Download"
                }
              >
                <span
                  className={`datagrid_download
                    ${
                      ((appDetails?.contentTypeId ===
                        Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES &&
                        downloadVisible &&
                        gridData.length < 1) ||
                        (appDetails?.contentTypeId !==
                          Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES &&
                          (selectedCount === 0 || downloadVisible))) &&
                      "datagrid_disabled"
                    }`}
                  onClick={() => {
                    handleDownloadClick();
                  }}
                  style={
                    displayDownload && !checkIfDeviceIsMob()
                      ? {}
                      : { display: "none" }
                  }
                >
                  <HiDownload className="datagrid_icon_mb" />
                </span>
              </span>
            )}
            {displayPPODUpload && (
              <span tabIndex={0} title={"Upload"}>
                <span
                  className={`datagrid_ppodupload
                      ${
                        (selectedCount === 0 || uploadExcelPPODDisabled) &&
                        "datagrid_disabled"
                      }`}
                  onClick={() => {
                    if (!uploadExcelPPODDisabled) {
                      handleUploadExcelPPOD(selectedItems);
                    }
                  }}
                  style={displayPPODUpload ? {} : { display: "none" }}
                >
                  <MdFindReplace className="datagrid_icon_mb" />
                </span>
              </span>
            )}
            {displayPublish && !tpAlerts && (
              <span
                tabIndex={0}
                title={Constant.INTERNAL_APPS_ICONS_TOOLTIP.PUBLISH}
              >
                <span
                  className={`datagrid_publish
                    ${
                      (selectedCount === 0 || publishVisible) &&
                      "datagrid_disabled"
                    }`}
                  onClick={() => {
                    handlePublishClick();
                  }}
                  style={
                    (displayPublish && !checkIfDeviceIsMob()) ||
                    contentTypeMappingForInternalApps[appDetails?.contentTypeId]
                      ? {}
                      : { display: "none" }
                  }
                >
                  <MdOutlineCloudUpload className="datagrid_icon_mb" />
                </span>
              </span>
            )}
            {displayDelete && (
              <span
                tabIndex={0}
                title={Constant.INTERNAL_APPS_ICONS_TOOLTIP.DELETE}
              >
                <span
                  className={`datagrid_delete
                    ${
                      (selectedCount === 0 || deleteVisible) &&
                      "datagrid_disabled"
                    }`}
                  onClick={() => {
                    handleDeleteClick();
                  }}
                  style={displayDelete ? {} : { display: "none" }}
                >
                  <HiOutlineTrash className="datagrid_icon_mb" />
                  {/* <span className="datagrid_menulabel">Delete</span> */}
                </span>
              </span>
            )}
            {displayRefresh && (
              <span
                tabIndex={0}
                title={Constant.INTERNAL_APPS_ICONS_TOOLTIP.REFRESH}
              >
                <span
                  className={`datagrid_refresh
                  ${!disableRefresh ? "datagrid_disabled" : ""}
                  ${displayRefresh ? "" : "refresh_hidden"}`}
                  onClick={() => {
                    handleRefreshClick();
                  }}
                  style={displayRefresh ? {} : { display: "none" }}
                >
                  <HiRefresh className="datagrid_icon_mb" />
                </span>
              </span>
            )}
            {(isDislike || isChat || isShare || isCalendar) &&
              displayActionItems && <div className="seperateBorder"></div>}
            {isDislike && (
              <span
                tabIndex={0}
                title={Constant.INTERNAL_APPS_ICONS_TOOLTIP.THUMBSDOWN}
              >
                <span
                  className={`datagrid_ActionItems
                    ${
                      (selectedCount === 0 || actionItemsVisible) &&
                      "datagrid_disabled"
                    }`}
                  onClick={() => {
                    handleDislikeAction();
                  }}
                  style={
                    // displayActionItems && !checkIfDeviceIsMob()
                    (displayActionItems && !checkIfDeviceIsMob()) ||
                    contentTypeMappingForInternalApps[appDetails?.contentTypeId]
                      ? {}
                      : { display: "none" }
                  }
                >
                  <MdThumbDown className="datagrid_icon_mb" />
                </span>
              </span>
            )}
            {isChat && !tpAlerts && (
              <span
                tabIndex={0}
                title={Constant.INTERNAL_APPS_ICONS_TOOLTIP.CHAT}
              >
                <span
                  className={`datagrid_ActionItems
                    ${
                      (selectedCount === 0 || actionItemsVisible) &&
                      "datagrid_disabled"
                    }`}
                  onClick={() => {
                    //handleLikeAction();
                  }}
                  style={
                    // displayActionItems && !checkIfDeviceIsMob()
                    (displayActionItems && !checkIfDeviceIsMob()) ||
                    contentTypeMappingForInternalApps[appDetails?.contentTypeId]
                      ? {}
                      : { display: "none" }
                  }
                >
                  <MdChatBubble className="datagrid_icon_mb" />
                </span>
              </span>
            )}
            {isCalendar && !tpAlerts && (
              <span
                tabIndex={0}
                title={Constant.INTERNAL_APPS_ICONS_TOOLTIP.CALENDER}
              >
                <span
                  className={`datagrid_ActionItems
                    ${
                      (selectedCount === 0 || actionItemsVisible) &&
                      "datagrid_disabled"
                    }`}
                  onClick={() => {
                    //handleLikeAction();
                  }}
                  style={
                    // displayActionItems && !checkIfDeviceIsMob()
                    (displayActionItems && !checkIfDeviceIsMob()) ||
                    contentTypeMappingForInternalApps[appDetails?.contentTypeId]
                      ? {}
                      : { display: "none" }
                  }
                >
                  <MdCalendarToday className="datagrid_icon_mb" />
                </span>
              </span>
            )}
            {isShare && !tpAlerts && (
              <span
                tabIndex={0}
                title={Constant.INTERNAL_APPS_ICONS_TOOLTIP.SHARE}
              >
                <span
                  className={`datagrid_ActionItems
                    ${
                      (selectedCount === 0 || actionItemsVisible) &&
                      "datagrid_disabled"
                    }`}
                  onClick={() => {
                    //handleLikeAction();
                  }}
                  style={
                    // displayActionItems && !checkIfDeviceIsMob()
                    (displayActionItems && !checkIfDeviceIsMob()) ||
                    contentTypeMappingForInternalApps[appDetails?.contentTypeId]
                      ? {}
                      : { display: "none" }
                  }
                >
                  <MdSend className="datagrid_icon_mb" />
                </span>
              </span>
            )}
          </div>
          {selectedCount > 0 && (
            <div
              tabIndex={0}
              className="clearSelection"
              title="Clear Selection"
            >
              <MdClose
                className="clearSelection-clearBtn"
                onClick={clearSelection}
              />
              <p>{`${selectedCount} selected`}</p>
            </div>
          )}
        </div>
        <div className="detailslist_wrapper" ref={scrollDiv}>
          <DetailsList
            items={gridData}
            columns={_columns}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            selection={_selection.current}
            selectionPreservedOnEmptyClick={true}
            ariaLabelForSelectionColumn="Toggle selection"
            ariaLabelForSelectAllCheckbox="Toggle selection for all items"
            checkButtonAriaLabel="select row"
            focusZoneProps={
              onkeydown={onKeyDown}
             }
            selectionMode={
              (displayDelete ||
                displayDownload ||
                displayEdit ||
                displayView) &&
              appDetails?.contentTypeId !==
                Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES
                ? SelectionMode.multiple
                : SelectionMode.none
            }
            onRenderCheckbox={(props) => {
              if (
                (displayDelete ||
                  displayDownload ||
                  displayEdit ||
                  displayView) &&
                appDetails?.contentTypeId !==
                  Constant.INTERNAL_APPS_CONTENT_TYPE_IDS.FOOTNOTES
              ) {
                return (
                  <span>
                    <input
                      tabIndex={0}
                      type="checkbox"
                      className="datagrid_checkbox"
                      checked={props.checked}
                    />
                  </span>
                );
              }
            }}
            onRenderItemColumn={_renderItemColumn}
            checkboxVisibility={1}
            onActiveItemChanged={() => {}}
            constrainMode={ConstrainMode.unconstrained}
            onRenderDetailsHeader={(headerProps, defaultRender) => {
              if (window.innerWidth >= 900) {
                return defaultRender({
                  ...headerProps,
                  onRenderColumnHeaderTooltip: (tooltipHostProps) => {
                    return <TooltipHost {...tooltipHostProps} />;
                  },
                  styles: {
                    root: {
                      selectors: {
                        "div.ms-DetailsHeader-cellIsCheck": {
                          visibility: isSelectAllCheckboxVisible
                            ? "visible !important"
                            : "hidden !important",
                        },
                      },
                    },
                  },
                });
              } else {
                return defaultRender({
                  ...headerProps,
                  onRenderColumnHeaderTooltip: (tooltipHostProps) => {
                    return <TooltipHost {...tooltipHostProps} />;
                  },
                  styles: {
                    root: {
                      selectors: {
                        ".ms-DetailsHeader-cell": {
                          whiteSpace: "normal",
                          textOverflow: "clip",
                          lineHeight: "normal",
                        },
                        ".ms-DetailsHeader-cellTitle": {
                          height: "100%",
                          alignItems: "center",
                        },
                        "div.ms-DetailsHeader-cellIsCheck": {
                          visibility: isSelectAllCheckboxVisible
                            ? "visible !important"
                            : "hidden !important",
                        },
                      },
                    },
                  },
                });
              }
            }}

            //onItemInvoked={this._onItemInvoked}
          />
          {contentTypeMappingForInternalApps[appDetails?.contentTypeId] &&
          gridData.length < 1 ? (
            <div className="noMatchRecords">No matching records found</div>
          ) : (
            ""
          )}
        </div>
        {
          <DialogBox
            openDialog={isOpen}
            content={
              <div className={`row_wrapper`}>
                <Label
                  content={Constant.INTERNALAPP_CONSTANTS.FEEDBACK_QUESTION}
                />
                <TextArea
                  placeholder={
                    Constant.INTERNALAPP_CONSTANTS.FEEDBACK_PLACEHOLDER
                  }
                  required={true}
                  height={"120px"}
                  onChange={onDislikeFeebackChange}
                  value={disLikeFeeback}
                  validations={[
                    {
                      name: "MaxLength",
                      maxChar: 2000,
                      errorMessage: "Length cannot be more than 2000",
                    },
                  ]}
                  name={"DisLikeFeedback"}
                  hasAnyError={errorState?.["DisLikeFeedback"]?.hasAnyError}
                  errMessage={errorState?.["DisLikeFeedback"]?.errorMessage}
                  tabIndex={0}
                />
                <div className="noteMessageContainer">
                  <div className="noteMessage">
                    {Constant.INTERNALAPP_CONSTANTS.FEEDBACK_NOTE_MESSAGE}
                  </div>
                </div>
              </div>
            }
            headerName={Constant.INTERNALAPP_CONSTANTS.FEEDBACK}
            headerActionName={{
              icon: <GrClose />,
              title: "Close",
              onClick: disLikeFeeback ? handleCancelClick : cancelSubmit,
            }}
            backdrop={true}
            className={`dialogDeleteStylesInternal dialogHeaderInternal dialogBoxInternal`}
            closeOnOutsideClick={false}
            footer={
              <FooterTechButtons
                disabled={isConfirmButtonDisabled}
                cancelText={CANCEL_TEXT}
                sendText={BTN_SUBMIT_FEEDBACK}
                onClickAddData={handleDisLikeFeeback}
                onClickCancel={
                  disLikeFeeback ? handleCancelClick : cancelSubmit
                }
                tabIndex={0}
              />
            }
          />
        }
        {
          <DialogBox
            openDialog={isConfirmDialogOpen}
            content={
              <div>
                {Constant.INTERNALAPP_CONSTANTS.CONFIRMATION_NOTE_MESSAGE}
              </div>
            }
            headerName={Constant.INTERNALAPP_CONSTANTS.CONFIRMATION_HEADER}
            headerActionName={{
              icon: <GrClose />,
              title: "Close",
              onClick: () => setIsConfirmDialogOpen(false),
            }}
            backdrop={true}
            className="dialogDeleteStylesInternal dialogHeaderInternal dialogBoxInternal"
            closeOnOutsideClick={false}
            footer={
              <FooterTechButtons
                cancelText={Constant.INTERNALAPP_CONSTANTS.NO_CONFIRMATION}
                sendText={Constant.INTERNALAPP_CONSTANTS.YES_CONFIRMATION}
                onClickAddData={goBackToGrid}
                onClickCancel={() => setIsConfirmDialogOpen(false)}
                tabIndex={0}
              />
            }
          />
        }
      </div>
      {contextualMenuProps && <ContextualMenu {...contextualMenuProps} />}
    </>
  );
}

export default DataGrid;
